<template>
	<teleport to="body">
		<div class="modal" id="orders-modal-view" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content shadow-3" v-if="order">
					<div class="modal-header">
						<div>
							<h4 class="modal-title d-flex align-items-center gap-4">
								Pedido N°: {{ order.number }}
								<div class="vr opacity-20 my-1"></div>
								<span class="text-muted">ID: {{ order.id }}</span>
							</h4>
							<small class="d-block">Creado el {{ getCreatedAtFormatted }}</small>
						</div>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body pt-0">
						<div class="row">
							<div class="col-12 px-0">
								<div class="list-group list-group-flush">
									<div class="list-group-item px-5 py-3" v-if="order.user">
										<user-card-reputation :user="order.user" />
									</div>
									<div class="list-group-item px-5 py-3 d-flex justify-content-between align-items-center" v-if="order.order_group_id">
										<div class="fw-semibold text-warning"><i class="bi bi-info-circle me-2"></i>El pedido pertenece a una orden general.</div>
									</div>
									<div class="list-group-item px-5 py-3 d-flex justify-content-between align-items-center" v-if="order.promocodes.length > 0">
										<div class="fw-semibold text-danger"><i class="bi bi-info-circle me-2"></i>Se utilizó un código promocional en esta compra.</div>
									</div>
									<div class="list-group-item px-5 py-0 d-flex justify-content-between align-items-center">
										<div class="fw-semibold py-3">Tipo de servicio</div>
										<div class="badge rounded-pill border ms-2" :class="typeInfo.badge">{{ typeInfo.title }}</div>
									</div>
									<div class="list-group-item px-5 py-0 d-flex justify-content-between align-items-center" v-if="order.code">
										<div class="fw-semibold py-3">Código</div>
										<div class="ms-auto text-end text-sm text-muted">{{ order.code.description }}</div>
									</div>
									<div class="list-group-item px-5 py-3 d-flex justify-content-between align-items-center" v-if="order.status == 'rejected'">
										<div class="fw-semibold text-danger">Rechazado</div>
										<div class="ms-auto text-end text-sm text-danger">{{ order.rejected_reason_message }}</div>
									</div>
									<div class="list-group-item px-5 py-3 d-flex justify-content-between align-items-center" v-if="order.phone">
										<div class="fw-semibold">Teléfono</div>
										<a :href="`https://wa.me/${order.phone}`" target="_blank" class="ms-auto text-end text-sm text-muted d-flex align-items-center">
											{{ phoneFormatted }} <i class="bi bi-whatsapp ms-2"></i>
										</a>
									</div>
									<div class="list-group-item px-5 py-3 d-flex justify-content-between align-items-center" v-if="order.type == 'DELIVERY'">
										<div class="fw-semibold">Dirección</div>
										<a href="javascript:void(0)" class="ms-auto text-end text-sm text-muted d-flex align-items-center" @click="addressImageShow = !addressImageShow;">
											{{ order.address ?? 'Sin descripción' }} <i class="bi bi-geo-alt-fill ms-2"></i>
										</a>
									</div>
								</div>
							</div>
							<div class="col-12 mt-0" v-if="order.type == 'DELIVERY' && order?.coordinates && addressImageShow">
								<div class="border rounded-2 overflow-hidden position-relative">
									<img :src="addressImageSrc" class="img-fluid" @load="addressImageReady = true" v-show="addressImageReady">
									<Skeletor width="100%" class="rounded" style="aspect-ratio: 6 / 5;" v-show="!addressImageReady" />
									<a :href="`https://www.google.com/maps/dir/?api=1&destination=${order.coordinates.coordinates.join(',')}`" target="_blank" class="btn btn-sm btn-neutral position-absolute end-0 top-0 m-2">Abrir</a>
								</div>
							</div>
							<div class="col-12 mt-5">
								<h5>Detalle del pedido</h5>
							</div>
							<div class="col-12">
								<div class="list-group list-group-flush mt-1">
									<orders-item v-for="(item, index) in order.items" v-bind:key="item.id" :item="item" :index="index" />
									<div class="list-group-item px-0 pb-0 fw-semibold">
										<div class="d-flex justify-content-between mb-2" v-if="subtotal != total">
											<div class="progress-text mb-0 d-block">Subtotal</div>
											<div class="text-end">{{ vueNumberFormat(subtotal, $personalizer.currency.value) }}</div>
										</div>
										<div class="d-flex justify-content-between mb-2" v-if="discount > 0">
											<div class="text-danger progress-text mb-0 d-block">Descuento</div>
											<div class="text-end text-danger">{{ vueNumberFormat(discount, $personalizer.currency.value) }}</div>
										</div>
										<div class="d-flex justify-content-between mb-2" v-if="order.type == 'DELIVERY'">
											<div class="progress-text mb-0 d-block">Envío</div>
											<div class="text-end">{{ vueNumberFormat(delivery_amount, $personalizer.currency.value) }}</div>
										</div>
										<div class="d-flex justify-content-between h5">
											<div class="progress-text mb-0 d-block">Total</div>
											<div class="text-end">{{ vueNumberFormat(total, $personalizer.currency.value) }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 mt-5" v-if="order.comments">
								<div class="alert alert-warning px-3 py-2" role="alert">
									<strong>Aclaraciones:</strong> {{ order.comments }}
								</div>
							</div>
							<div class="col-12 mt-5" v-if="hasShipping">
								<orders-kanban-card-shipping :status="order.status" :rider="shipping?.response?.dboy_id" :shippingInfo="shippingInfo" />
							</div>
							<div class="col-12 mt-5" v-if="canPayment">
								<h5>Forma de pago</h5>
							</div>
							<div class="col-12 mt-3" v-if="canPayment">
								<payment-block :total="order.total" :saved="order.payments" @setPayments="setPayments" />
							</div>
						</div>
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<div class="dropup">
							<button class="btn btn-sm btn-warning dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								Opciones
							</button>
							<ul class="dropdown-menu">
								<li v-if="!isPlanFree"><a href="javascript:void(0);" class="dropdown-item" @click="printCommander(order.id)"><i class="bi bi-printer pe-2"></i>Imprimir comanda</a></li>
								<li v-if="!isPlanFree"><a href="javascript:void(0);" class="dropdown-item" @click="printSummary('order', order.id)"><i class="bi bi-printer pe-2"></i>Imprimir resumen</a></li>
								<li v-if="isPlanPremium && (canBilling && order.billings_count > 0)"><a href="javascript:void(0);" class="dropdown-item" @click="printInvoice('order', order.id)"><i class="bi bi-printer pe-2"></i>Imprimir factura</a></li>
								<hr v-if="!isPlanFree && (canOpenTicket || canDiscount || (canBilling && order.billings_count == 0))" class="dropdown-divider my-2" />
								<li v-if="canOpenTicket && isAdmin"><a href="javascript:void(0);" class="dropdown-item text-info" @click="ticket"><i class="bi bi-chat-right-text pe-2"></i>Ticket</a></li>
								<li v-if="isPlanPremium && (canBilling && order.billings_count == 0)"><router-link :to="{ name: 'store.order.billing.invoice', params: { store_id: current_store.id, id: order.id } }" class="dropdown-item"><i class="bi bi-receipt-cutoff pe-2"></i>Facturar</router-link></li>
								<li v-if="canDiscount"><a href="javascript:void(0);" class="dropdown-item text-warning" @click="modalOrderTotalShow"><i class="bi bi-percent pe-2"></i>Aplicar descuento</a></li>
								<li v-if="canDiscount"><a href="javascript:void(0);" class="dropdown-item text-danger" @click="modalOrderPromocodeShow"><i class="bi bi-braces-asterisk pe-2"></i>Aplicar código promocional</a></li>
							</ul>
						</div>
						<div class="btn-group btn-group-sm dropup">
							<button type="button" class="btn btn-dark" :disabled="submitting" @click="submit()">Guardar</button>
							<button type="button" class="btn btn-dark border-start dropdown-toggle dropdown-toggle-split" :disabled="submitting" data-bs-toggle="dropdown" aria-expanded="false">
								<span class="visually-hidden">Toggle Dropstart</span>
							</button>
							<ul class="dropdown-menu">
								<li v-if="order.status != 'PENDING'"><a href="javascript:void(0);" class="dropdown-item" @click="submit('PENDING')">Pendiente</a></li>
								<li v-if="order.status != 'ACCEPTED'"><a href="javascript:void(0);" class="dropdown-item" @click="submit('ACCEPTED')">Aceptado</a></li>
								<li v-if="order.status != 'DELIVER'"><a href="javascript:void(0);" class="dropdown-item" @click="submit('DELIVER')">Por entregar</a></li>
								<li v-if="order.status != 'COMPLETE'"><a href="javascript:void(0);" class="dropdown-item" @click="submit('COMPLETE')">Entregado</a></li>
								<hr class="dropdown-divider" v-if="order.status != 'REJECTED'" />
								<li v-if="order.status != 'REJECTED'"><a href="javascript:void(0);" class="dropdown-item text-danger" @click="reject"><i class="bi bi-trash pe-2"></i>Rechazar</a></li>
								<hr class="dropdown-divider" v-if="getStatusReadyBy" />
								<li class="dropdown-item" v-if="getStatusReadyBy">
									<div class="text-xs text-muted mb-2"><i class="bi bi-clock-history pe-2"></i>Agregar minutos de demora</div>
									<div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
										<button type="button" class="btn btn-neutral px-3" :disabled="submitting" @click="delay(5)">+5</button>
										<button type="button" class="btn btn-neutral px-3" :disabled="submitting" @click="delay(10)">+10</button>
										<button type="button" class="btn btn-neutral px-3" :disabled="submitting" @click="delay(15)">+15</button>
										<button type="button" class="btn btn-neutral px-3" :disabled="submitting" @click="delay(30)">+30</button>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>

	<common-order-promocode @setPromocode="setPromocode" />
	<common-order-total :subtotal="order.subtotal" @setDiscount="setDiscount" v-if="order" />
</template>

<script>
	import { ref, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { Modal, Offcanvas } from 'bootstrap';
	import _ from 'lodash';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import composableStore from '@/composables/store';
	import composableOrder from '@/composables/order';
	import composableForm from '@/composables/form';
	import { printCommander, printSummary, printInvoice } from '@/helpers/Printpoint';

	export default {
		emits: ['select', 'selectReject', 'selectTicket', 'selectTicketNew'],
		props: {
			order: Object
		},
		setup(props, { emit }) {
			const { current_store, isPlanFree, isPlanPremium, isAdmin } = composableStore();
			const { reload, phoneFormatted, subtotal, discount, delivery_amount, total, hasShipping, canOpenTicket, shipping, canDiscount, canPayment, canBilling, setDiscount, setPromocode, typeInfo, getCreatedAtFormatted, getStatusReadyBy, getReadyByFormatted, shippingInfo } = composableOrder();
			const { submitting, catchError } = composableForm();
			const modalView = ref(null);
			const order = ref(props.order);

			const payments = ref([]);
			const setPayments = (value) => payments.value = value;

			const items = computed(() => {
				var items_assoc = [],
					items = [];

				_.forEach(order.value.order_detail, function(item) {
					if(item.ref == null) {
						items_assoc[item.id] = item;
						items_assoc[item.id].additionals = [];
					}else items_assoc[item.ref].additionals.push(item);
				});

				_.forEach(items_assoc, function(item) {
					items.push(item);
				});

				return items;
			});

			onMounted(() => {
				modalView.value = new Modal(document.getElementById('orders-modal-view'));

				document.getElementById('orders-modal-view').addEventListener('hidden.bs.modal', () => {
					emit('select', null);
					addressImageShow.value = false;
					addressImageReady.value = false;
				});
			});

			watch(() => props.order, (newValue) => {
				if(newValue) {
					order.value = newValue;
					reload(order.value);
					modalView.value.show();
				}
			});

			const submit = async (status = null) => {
				var data = {
					status: status,
					payments: payments.value,
					subtotal: subtotal.value,
					discount: discount.value,
					delivery_amount: delivery_amount.value,
					total: total.value
				}

				if(submitting.value) return false;

				submitting.value = true;
				await OrdersRepository.update(current_store.value.id, order.value.id, data)
					.then((response) => {
						modalView.value.hide();
						if(response.data.status == 'PAYING') router.push({ name: 'paying', params: { store_id: current_store.value.id, type: 'order', id: response.data.id } });
					})
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			const delay = async (minutes) => {
				if(submitting.value) return false;

				submitting.value = true;
				await OrdersRepository.delay(current_store.value.id, order.value.id, { minutes: minutes })
					.then(() => modalView.value.hide())
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			const reject = () => {
				emit('selectReject', order.value);
				modalView.value.hide();
			}

			const ticket = () => {
				var ticket = _.find(store.getters['tickets/list'], { order_id: order.value.id, status: 'open' });
				modalView.value.hide();

				if(ticket) emit('selectTicket', ticket);
				else emit('selectTicketNew', order.value);
			}

			const modalOrderTotalShow = () => {
				if(submitting.value) return;
				Offcanvas.getInstance(document.getElementById('common-order-total')).show();
			}

			const modalOrderPromocodeShow = () => {
				if(submitting.value) return;
				Offcanvas.getInstance(document.getElementById('common-order-promocode')).show();
			}

			const addressImageShow = ref(false);
			const addressImageReady = ref(false);
			const addressImageSrc = computed(() => {
				if(order.value?.coordinates) return `https://maps.googleapis.com/maps/api/staticmap?center=${order.value.coordinates.coordinates.join(',')}&zoom=18&size=600x500&maptype=roadmap&markers=${order.value.coordinates.coordinates.join(',')}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
				else return null;
			});

			return { current_store, isPlanFree, isPlanPremium, isAdmin, items, phoneFormatted, subtotal, discount, delivery_amount, total, hasShipping, canOpenTicket, shipping, canDiscount, canPayment, canBilling, typeInfo, getCreatedAtFormatted, getStatusReadyBy, getReadyByFormatted, shippingInfo, addressImageShow, addressImageReady, addressImageSrc, submitting, submit, delay, reject, ticket, modalOrderTotalShow, setDiscount, modalOrderPromocodeShow, setPromocode, printCommander, printSummary, printInvoice, payments, setPayments };
		}
	}
</script>
