<template>
	<div v-if="type.option == 'LOCAL'">
		<h4>Local</h4>
		<small class="d-block">En <b>{{ codeDescription }}</b></small>
	</div>
	<div v-if="type.option == 'TAKEAWAY'">
		<h4>Take away</h4>
	</div>
	<div v-if="type.option == 'DELIVERY'">
		<h4>Delivery</h4>
		<small class="d-block">Enviar a <b>{{ locationDescription }}</b>.</small>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import composableCart from '@/composables/cart';

	export default {
		setup() {
			const { type, code, address } = composableCart();

			const codeDescription = computed(() => {
				if(code.value) return code.value.description;
				else return null;
			});

			const locationDescription = computed(() => {
				var descriptionArray = [];

				if(address.value?.address_line_1) descriptionArray.push(address.value.address_line_1);
				if(address.value?.address_line_2) descriptionArray.push(`(${address.value.address_line_2})`);

				return (descriptionArray.length > 0) ? descriptionArray.join(' ') : 'Sin descripción';
			});

			return { type, codeDescription, locationDescription };
		}
	}
</script>