<template>
	<div id="v-guide" class="guide bg-secondary text-white p-4 pt-3 rounded-2 d-none" :style="{'zIndex': (stepCurrent?.zIndex ?? 10)}">
		<div class="vstack gap-3" v-if="stepCurrent">
			<div class="lh-sm text-sm" v-html="stepCurrent.content"></div>
			<div class="hstack gap-3">
				<button type="button" class="btn btn-xs text-sm lh-xs btn-outline-danger w-100" @click="guideEnd">Cerrar</button>
				<button type="button" class="btn btn-xs text-sm lh-xs btn-outline-light w-100" @click="guideNext" v-if="hasNext">Siguiente</button>
			</div>
		</div>
		<div id="v-guide-arrow" data-popper-arrow></div>
	</div>

	<guide-select ref="modalSelect" :guides="translation.guides" @guideSelect="guideSelect" />
</template>

<script>
	import { onMounted } from 'vue';
	import store from '@/store';
	import { stepCurrent, guideModal, modalSelect, guideSelect, guideEnd, hasNext, guideNext, guideInfo } from '@/helpers/Guide';
	import translation from '@/helpers/i18n/translations/guide/es.json';

	export default {
		setup() {
			onMounted(() => {
				if(!store.getters['config/guide'] && window.innerWidth > 991) guideInfo();
			});

			return { translation, stepCurrent, guideModal, modalSelect, guideSelect, guideEnd, hasNext, guideNext };
		}
	}
</script>